<template>
  <v-card class="pa-2 mt-2">
    <v-card-text>
      <div class="pt-2">
        <v-alert prominent type="error">
          Minimum requirement: 600 CPs after 3 years (200 CP on average per year; full-time position, reduced accordingly for part-time). In total, doctoral researchers are not allowed to receive credit for language courses in excess of 300 CP (50%) with language courses.
        </v-alert>
      </div>

      <qualification-plan-editor :qualification-plan-id="qualificationPlanId" @after-save="backToOverview" />
    </v-card-text>
  </v-card>
</template>

<script>
import QualificationPlanStatus from "@/modules/qualification-plan/component/qualification-plan-status/qualification-plan-status";
import Vue, {defineComponent, onMounted, ref, reactive, set, watchEffect} from "vue";
import {
  getAllCategories,
  getPlanById,
  updateActivities
} from "@/modules/qualification-plan/api/api";
import {useRouter} from "vue2-helpers/vue-router";
import _ from "lodash";
import QualificationPlanEditor from "@/modules/qualification-plan/component/qualification-plan-editor.vue";

export default defineComponent({
  name: "qualification-plan-edit",
  components: {QualificationPlanEditor, QualificationPlanStatus},
  props: {
    qualificationPlanId: {}
  },
  setup(props) {
    const router = useRouter();

    const backToOverview = () => {
      router.push({name: 'qualification-plan-index'});
    }

    return {
      backToOverview
    }
  }
});
</script>

<style scoped>

</style>
